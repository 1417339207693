import React, { useState, useEffect } from 'react';
import { animated, config, useSpring, useTransition } from '@react-spring/web';
import desktopImage from '../images/gp_bg@2x.jpg';

type Slide = {
  id: number,
  image: string
}

const slides: Slide[] = [
  {
    id: 0,
    image: 'https://images.unsplash.com/photo-1599608214475-701c84df7bdd'
  },
  {
    id: 1,
    image: 'https://images.unsplash.com/photo-1623184185917-d2e8ec0daa27'
  },
  {
    id: 2,
    image: 'https://images.unsplash.com/photo-1599666782476-691b0014fd87'
  },
  {
    id: 3,
    image: desktopImage
  }
].sort((a, b) => 0.5 - Math.random());

const Carousel = () => {
  const [index, setIndex] = useState<number>(0);
  const [shuffledSlides] = useState<Slide[]>(slides);
  const duration = 50000;

  const CarouselImage = ({ url, duration }: { url: string, duration: number }) => {
    const props = useSpring({
      from: {
        transform: 'scale(1) translateX(0%)',
      },
      to: {
        transform: 'scale(1.314) translateX(-10%)',
      },
      config: {
        duration: duration - 200,
      },
    })
    return <animated.div className="bg" style={{ ...props, backgroundImage: `${url}` }} />
  }

  const CarouselImages = () => {
    const transitions = useTransition(shuffledSlides[index], {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.molasses,
    })
    return (
      <>
        {transitions((style, slide) => (
          <animated.div key={slide.id} className={'bg'} style={{...style}}>
            <CarouselImage url={`url(${slide.image})`} duration={duration} />
          </animated.div>
        ))}
      </>
    )
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      let nextIndex = (index + 1) % shuffledSlides.length;
      setIndex(nextIndex);
    }, duration);
    return () => clearInterval(interval);
  }, [index, shuffledSlides.length])

  return (
    <CarouselImages />
  )
}

export default Carousel;
