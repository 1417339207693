import React, { useEffect } from 'react';

const TermsOfUse = () => {

  useEffect(() => {
    document.title = 'Georgia\u205fPlaces | Terms of Use';
  }, []);

  return (
    <main className="page-blog-post">
      <div className="page-container">
        <article className="box is-large is-gray is-borderless blog-post-container">
          <div className="featured-image"
               style={{ backgroundImage: "url('/img/privacy_terms_background.jpg')"}} />
          <div className="box is-large mb-0 blog-post-content">
            <h1 className="blog-post-headline">
              Terms of Use
            </h1>
            <p>
              Last updated: May 31, 2022
            </p>
            <div className="box is-gray mb-6">
              <p>
                From everyone here at Georgia Places, thank you for using our products and services!
                Please take a moment to read these Terms of Use carefully.
              </p>
            </div>
            <p>
              When we say "Company", "Companies", "we", "our", or "us" in this document, we are referring to
              Georgia Places, Inc. A United States company operating in the State of Georgia.
            </p>
            <p>
              When we say "Services", we mean any product or services created and maintained by Georgia Places, Inc.
            </p>
            <p>
              When we say "You" or "your", we are referring to the people or organizations that own an account with
              one or more of our services.
            </p>
            <p>
              These Terms of Use may be updated in the future. Typical changes will be to clarify some of these
              terms by linking to an expanded related policy. Whenever we make a significant change to our policies,
              we will also notify users of these updates.
            </p>
            <p>
              When you use our Services, now or in the future, you are agreeing to the latest Terms of Use. That’s
              true for any of our existing and future products and services and all features that we add to our
              Services over time. There may be times where we do not exercise or enforce any right or provision of the
              Terms of Use; in doing so, we are not waiving that right or provision. These terms do contain a
              limitation of our liability.
            </p>
            <p>
              If you violate any of the terms, we may terminate your account. This is a broad statement and means you
              are placing a lot of trust in us. We work hard to deserve that trust by being transparent about who we
              are, how we work, and keeping an open door to your feedback.
            </p>
            <h5>Account terms</h5>
            <p>
              <ul className="list">
                <li>
                  You are responsible for maintaining the security of your account and password. The Companies cannot
                  and will not be liable for any loss or damage from your failure to comply with this security
                  obligation.
                </li>
                <li>
                  You may not use our products or services for any purpose outlined in our Use Restrictions policy
                  listed below.
                </li>
                <li>
                  You are responsible for all activity that occurs under your account. That includes activity by others
                  who either: (a) have access to your login credentials; or (b) have their own logins under your
                  account.
                </li>
                <li>
                  You must be a human being. Accounts registered by “bots” or other automated methods are not permitted.
                </li>
              </ul>
            </p>
            <h5>Use Restrictions policy</h5>
            <p>
              We understand that technology can be used to cause great harm. That is why we have established this
              policy. We feel an ethical obligation to counter such harm: both in terms of dealing with instances where
              Georgia Places, Inc. is used (and abused) to further such harm, and to state unequivocally that
              the products we make are not safe havens for people who wish to commit such harm. If you have an account
              with us, you can’t use such account for any of the restricted purposes listed below. If we discover that
              you are, action will be taken.
            </p>
            <p>
              <strong>Restricted purposes</strong>
              <ul className="list">
                <li>
                  <strong>Violence, or any related threats thereof:</strong> If an activity qualifies as a violent crime
                  in the United States or where you live, you may not use our products to plan, perpetrate, or threaten
                  that activity.
                </li>
                <li>
                  <strong>Child exploitation, sexualization, or abuse:</strong> We do not tolerate any activities that
                  create, disseminate, or otherwise cause child abuse. Stay away and stop.
                </li>
                <li>
                  <strong>Hate speech:</strong> You cannot use our products to advocate for the extermination,
                  domination, or oppression of people.
                </li>
                <li>
                  <strong>Harassment:</strong> Intimidating or targeting people or groups through repeated
                  communication, including using racial slurs or dehumanizing language, is not welcome in our products
                  and services.
                </li>
                <li>
                  <strong>Doxing:</strong> If you are using our products or services to share other peoples’ private
                  personal information for the purposes of harassment, we want nothing to do with you.
                </li>
                <li>
                  <strong>Malware or spyware:</strong> If you are using our products or services to make or distribute
                  anything that qualifies as malware or spyware — including, but not limited to, remote user
                  surveillance — you are not welcome here.
                </li>
                <li>
                  <strong>Phishing or otherwise attempting fraud:</strong> It is not okay to lie about who you are or
                  who you affiliate with to steal from, extort, or otherwise harm others.
                </li>
                <li>
                  <strong>Spamming:</strong> We will not tolerate those using our products or services for spamming
                  purposes. This includes the usage of "bots." If your emails do not adhere to CAN-SPAM or any other
                  anti-spam law, it is not allowed.
                </li>
                <li>
                  <strong>Cybersquatting:</strong> We very much dislike username squatters. If you purchase a email
                  address, product or service from us either utilizing a brand or persons name or related and then try
                  to sell that account to them, you are cybersquatting. Cybersquatting accounts are subject to
                  immediate cancellation. Don't do it.
                </li>
                <li>
                  <strong>Infringing on intellectual property:</strong> You cannot use our products or services to make
                  or disseminate work that uses the intellectual property of others beyond the bounds of fair use.
                </li>
              </ul>
            </p>
            <p>
              To sum all that up: Georgia Places, Inc. is not to be harnessed for harm, whether mental, physical,
              personal or civic.
            </p>
            <h5>How to report abuse</h5>
            <p>
              For cases of suspected malware, spyware, phishing, spamming, and cybersquatting, please alert us
              immediately at <a href="mailto:abuse@georgia.com">abuse@georgia.com</a>.
            </p>
            <p>
              For all other cases, please let us know by
              emailing <a href="mailto:abuse@georgia.com">abuse@georgia.com</a>. If you’re not 100% sure if something
              rises to the level of our use restrictions policy, please report it anyway.
            </p>
            <p>
              When submitting reports, please share as much as you are comfortable with about the account, the behavior
              you are reporting, and how you found it. Sending us a URL or screenshots is very helpful. We will never
              disclose your identity to anyone associated with the reported account. For copyright or trademark holders
              that believe a user of our Services has infringed on such please email
              us at <a href="mailto:feedback@georgia.com">feedback@georgia.com</a> and provide as much detail as possible
              about the claim. We will respond with more detailed information on how to proceed.
            </p>
            <p>
              Someone on our team will typically respond within two business days to inform you that we have started an
              investigation. Unless prohibited under law, or if you ask us not to, we will inform you of the outcome of
              our investigation.
            </p>
            <p>
              Termination of an account without notice means the decision was and is final.
            </p>
            <p>
              There are cases we would consider good faith appeals. Send all appeals
              to <a href="mailto:support@georgia.com">support@georgia.com</a> by the account owner within 14 calendar
              days.
            </p>
            <p>
              <i>*This policy and process applies to any product or service created and owned by us.</i>
            </p>
            <h5>Copyright and Content Ownership</h5>
            <p>
              <ul className="list">
                <li>
                  We do not pre-screen content, but reserve the right (but not the obligation) in our sole discretion
                  to refuse or remove any content that is available via the Service.
                </li>
                <li>
                  The names, look, and feel of the Services are copyright© to the Company. All rights reserved. You may
                  not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements
                  without express written permission from the Companies. You may not use the Companies logo or any
                  Service logos for pomotional purposes without written permission from the Companies. Email us
                  to request logo uses. We reserve the right to rescind this permission if you violate these Terms of
                  Service.
                </li>
                <li>
                  You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use
                  of the Services, or access to the Services without the express written permission by the Companies.
                </li>
                <li>
                  You must not modify another website, mobile app or similar so as to falsely imply that it is
                  associated with the Services or the Companies.
                </li>
              </ul>
            </p>
            <h5>Liability</h5>
            <p>
              <strong>
                You expressly understand and agree that the Companies shall not be liable, in law or in equity, to you
                or to any third party for any direct, indirect, incidental, lost profits, special, consequential,
                punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill,
                use, data or other intangible losses (even if the Companies have been advised of the possibility of
                such damages), resulting from: (i) the use or the inability to use the Services; (ii) the cost of
                procurement of substitute goods and services resulting from any goods, data, information or services
                purchased or obtained or messages received or transactions entered into through or from the Services;
                (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of
                any third party on the service; (v) or any other matter relating to this Terms of Use or the
                Services, whether as a breach of contract, tort (including negligence whether active or passive), or
                any other theory of liability.</strong>
            </p>
            <p>
              If you have any questions about any of the Terms of Use, please contact our support team.
            </p>
          </div>
        </article>
      </div>
    </main>
  )
}

export default TermsOfUse;
