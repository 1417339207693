import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';

// Pages
import Home from './pages/Home';
import Portal from './pages/Portal';
import PrivacyPolicy from './pages/Legal_PrivacyPolicy';
import TermsOfUse from './pages/Legal_TermsOfUse';
import NotFound from './pages/NotFound';

// SCSS
import './scss/app.scss';

function App() {
  return (
    <div className="app">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/legal/privacy" element={<PrivacyPolicy />} />
        <Route path="/legal/terms" element={<TermsOfUse />} />
        { /* 404 Page */ }
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
