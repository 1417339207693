import React, {FormEventHandler, FormEvent, ChangeEvent, useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { emailChecker, phoneFormatter } from '../Utils';
import Carousel from '../components/Carousel';
import { SubmitContactForm } from "../api";

type ContactFormFields = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  message: string;
  isError: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    message: string;
  }
}

const Home: React.FC = () => {

  const formFieldValues: ContactFormFields = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
    isError: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      message: '',
    }
  };

  const { hash } = useLocation();

  // Form
  const [ success, setSuccess ] = useState<boolean>(false);
  const [ formFields, setFormFields ] = useState<ContactFormFields>(formFieldValues);
  const [ processing, setProcessing ] = useState<boolean>(false);
  const [ errorMessage, setErrorMessage ] = useState<string>('');
  const [ honeyPotValue, setHoneyPotValue ] = useState<string>('');

  useLayoutEffect(() => {
    const scrollToAnchor = () => {
      if (hash) {
        // @ts-ignore
        document.getElementById(hash).scrollIntoView();
      }
    };
    scrollToAnchor();
  }, [hash]);

  const formValid = ({ isError, ...fields }: ContactFormFields) => {
    for (const val of Object.values(isError)) {
      if (val.length > 0) { return false; }
    }

    for (const [key, val] of Object.entries(fields)) {
      if (key !== "phone" && val.length <= 0) { return false; }
    }

    return true;
  };

  const inputOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...formFields.isError };
    // TODO: fix this TypeScript issue:
    // @ts-ignore
    isError[name] = "";

    setFormFields((prevState) => ({ ...prevState, isError }));

    let phoneValue: string | null = null;
    if (name === "phone") phoneValue = phoneFormatter(value, '');

    setFormFields((prevState) => ({ ...prevState, [name]: phoneValue || value}));
  };

  const inputOnBlur = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...formFields.isError };

    switch (name) {
      case "firstname":
        isError.firstname =
          value.length === 0
            ? ""
            : value.length < 2
              ? "Two characters required"
              : "";
        break;
      case "lastname":
        isError.lastname =
          value.length === 0
            ? ""
            : value.length < 2
              ? "Two characters required"
              : "";
        break;
      case "email":
        isError.email =
          value.length === 0
            ? ""
            : emailChecker(value)
              ? ""
              : "Email address is invalid";
        break;
      case "phone":
        isError.phone = value.length === 0
          ? ""
          : value.replace(/[^\d]/g, '').length < 10
            ? "Phone number is invalid"
            : "";
        break;
      case "message":
        isError.message =
          value.length === 0
            ? ""
            : value.length < 10
              ? "A short message is required."
              : "";
        break;
      default:
        break;
    }
    setFormFields((prevState) => ({ ...prevState, isError}));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Abort if form isn't valid
    if (!e.currentTarget.reportValidity()) return;
    if (!formValid(formFields)) return;

    if (!processing) {
      setProcessing(true);

      try {
        let values: {
          firstname: string;
          lastname: string;
          email: string;
          phone: string;
          message: string;
          isError?: {
            firstname: string;
            lastname: string;
            email: string;
            phone: string;
            message: string;
          }
        } = {...formFields};
        delete values.isError;
        const result = await SubmitContactForm(values);

        if (result.status === 202 || 200) {
          setSuccess(true);
        } else {
          setErrorMessage('There was an issue submitting your information. Please try again.');
        }
        setProcessing(false);
      } catch (e) {
        setErrorMessage('There was an issue submitting your information. Please try again.');
      }
    }
  }

  return (
    <main className="page-home">
      <div className="page-container">
        <section>
          <div className="box is-large home-carousel">
            <div className="carousel-banner">
              <p>Premier ambassadors to<br /> Georgia real estate.</p>
            </div>
            <Carousel />
          </div>
        </section>

        <section>
          <div className="box is-large is-transparent gp-has-background-circles">
            <div className="is-row has-column-spacing">
              <div className="is-col">
                <h1>Your premier ambassadors</h1>
                <p>
                  Georgia Places is currently serving the North Atlanta area. From Sandy Springs, Roswell, Milton and
                  Alpharetta to Woodstock, Canton, Holly Springs and Ball Ground.
                </p>
                <p>
                  Whether you're selling or trying to find your perfect home let Georgia Places Ambassadors show you
                  our difference.
                </p>
              </div>
              <div className="is-col">
                <figure className="image">
                  <img src={"/img/gp_state_of_georgia@2x.png"} alt="State of Georgia" />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="gp-full-width">
        <section>
          <div className="box is-large gp-contact-section">
            <div className="is-row">
              <div className="is-col gp-contact-container">
                <div className="gp-contact-circle">
                  <form onSubmit={handleSubmit}>
                    <h2>{ success ? '404.307.6394' : '404.307.6394' }</h2>
                    { success ? (
                      <p>Thank you!</p>
                    ) : (
                      <p>Send us a note!</p>
                    )}
                    { processing ? (
                      <div className="loader">Loading...</div>
                    ) : success ? (
                      <p>One of our ambassadors will be contacting you soon!</p>
                    ) : (
                      <>
                        <div className="gp-form-fields">
                          <div className={`form-item mr-2 is-col is-fullwidth-tablet-wide ${formFields.isError.firstname.length > 0 && "has-error"}`}>
                            <label className="is-sr-only label" htmlFor="firstname">First name</label>
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              className="input"
                              placeholder="First name"
                              autoComplete="on"
                              value={formFields.firstname}
                              tabIndex={1}
                              onChange={inputOnChange}
                              onBlur={inputOnBlur}
                              required
                            />
                            {formFields.isError.firstname.length > 0 && (
                              <span className="is-error-text">{formFields.isError.firstname}</span>
                            )}
                          </div>
                          <div className={`form-item is-col is-fullwidth-tablet-wide ${formFields.isError.lastname.length > 0 && "has-error"}`}>
                            <label className="is-sr-only label" htmlFor="lastname">Last name</label>
                            <input
                              type="text"
                              id="lastname"
                              name="lastname"
                              className="input"
                              placeholder="Last name"
                              autoComplete="on"
                              value={formFields.lastname}
                              tabIndex={2}
                              onChange={inputOnChange}
                              onBlur={inputOnBlur}
                              required
                            />
                            {formFields.isError.lastname.length > 0 && (
                              <span className="is-error-text">{formFields.isError.lastname}</span>
                            )}
                          </div>
                        </div>
                        <div className="gp-form-fields single-lines">
                          <div className={`form-item is-col is-fullwidth-tablet-wide ${formFields.isError.email.length > 0 && "has-error"}`}>
                            <label className="is-sr-only label" htmlFor="email">Email</label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              className="input"
                              value={formFields.email}
                              placeholder="Email address"
                              autoComplete="off"
                              onChange={inputOnChange}
                              onBlur={inputOnBlur}
                              required
                            />
                            {formFields.isError.email.length > 0 && (
                              <span className="is-error-text">{formFields.isError.email}</span>
                            )}
                          </div>
                          <div className={`form-item is-col is-fullwidth-tablet-wide ${formFields.isError.phone.length > 0 && "has-error"}`}>
                            <label className="is-sr-only label" htmlFor="phone">Recovery Phone</label>
                            <input
                              type='text'
                              id="phone"
                              name="phone"
                              className="input"
                              placeholder='Phone number'
                              value={formFields.phone}
                              autoComplete="off"
                              onChange={inputOnChange}
                              onBlur={inputOnBlur}
                              required
                            />
                            {formFields.isError.phone.length > 0 && (
                              <span className="is-error-text">{formFields.isError.phone}</span>
                            )}
                          </div>
                        </div>
                        <div className="gp-form-fields single-lines">
                          <div className={`form-item is-col is-fullwidth-tablet-wide ${formFields.isError.message.length > 0 && "has-error"}`}>
                            <label className="is-sr-only label" htmlFor="message">Message</label>
                            <textarea
                              id="message"
                              name="message"
                              className="input"
                              value={formFields.message}
                              placeholder="Message"
                              autoComplete="off"
                              onChange={inputOnChange}
                              onBlur={inputOnBlur}
                              required
                            />
                            {formFields.isError.message.length > 0 && (
                              <span className="is-error-text">{formFields.isError.message}</span>
                            )}
                          </div>
                        </div>
                        { /* Spam Honey Pot */ }
                        <input
                          type="text"
                          name="subject_message"
                          style={{display: "none"}}
                          tabIndex={-1}
                          value={honeyPotValue}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setHoneyPotValue(event.target.value)}
                          autoComplete="off" />
                        { errorMessage && <p className="is-error-text">{errorMessage}</p> }
                        <button type="submit" className="button is-black" disabled={!!errorMessage}>Contact Us</button>
                      </>
                    )}
                  </form>
                </div>
                <img className="contact-images-circles" src={"/img/gp_sign_up_bubbles_mobile.png"} alt="Georgia Imagery" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Home;
