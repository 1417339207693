import React, { ChangeEvent, useEffect, useState, MouseEvent } from 'react';

const Portal: React.FC = () => {
  const [ message, setMessage ] = useState<string>('');
  const [ username, setUsername ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');

  const handleLogin = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setMessage('');
    if (username && password) {
      setMessage('Please enter a valid email address and password.');
    }
  }

  return (
    <main className="page-blog-post">
      <div className="page-container">
        <article className="box is-large is-gray is-borderless blog-post-container">
          <div className="featured-image"
               style={{ backgroundImage: "url('/img/privacy_terms_background.jpg')"}} />
          <div className="box is-large blog-post-content">
            <h1 id="homepage-tagline" style={{ textAlign: "center" }}>Client &amp; Ambassador Portal</h1>
            <p style={{ textAlign: "center", fontStyle: "italic" }}>Experience the difference.</p>
            <div className="login-box">
              <form className="form">
                <div className="is-row has-column-spacing">
                  <div className="form-item is-col-2 is-mobile">
                    <label className="is-sr-only label" htmlFor="username">Username</label>
                    <input
                      type="text"
                      className="input"
                      id="username"
                      name="username"
                      placeholder="Email Address"
                      autoComplete="on"
                      tabIndex={1}
                      onChange={
                        (ev: ChangeEvent<HTMLInputElement>): void => setUsername(ev.target.value)
                      }
                      required
                    />
                  </div>
                </div>

                <div className="is-row has-column-spacing">
                  <div className="form-item is-col has-icon-right">
                    <label className="is-sr-only label" htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="input"
                      placeholder="Password"
                      autoComplete="on"
                      tabIndex={2}
                      onChange={
                        (ev: ChangeEvent<HTMLInputElement>): void => setPassword(ev.target.value)
                      }
                      required
                    />
                    { message && <p className="help is-error-text has-text-centered">{message}</p> }
                  </div>
                </div>
                { /* <!-- Submit button --> */ }
                { /* <!-- Add .is-loading class to activate spinner --> */ }
                <button
                  type="submit"
                  className="button has-text-uppercase is-fullwidth"
                  tabIndex={3}
                  onClick={(e) => handleLogin(e)}>Sign in</button>
              </form>
            </div>
          </div>
        </article>
      </div>
    </main>
  );
}

export default Portal;
