import React, { useEffect } from 'react';

const NotFound = () => {

  useEffect(() => {
    document.title = 'Georgia\u205fPlaces | Oops - Page not found';
  }, []);

  return (
    <main className="page-blog-post">
      <div className="page-container">
        <article className="box is-large is-gray is-borderless blog-post-container">
          <div className="featured-image"
               style={{ backgroundImage: "url('/img/privacy_terms_background.jpg')"}} />
          <div className="box is-large mb-0 blog-post-content">
            <h1 className="blog-post-headline">
              Hmm. This page does not exist.
            </h1>
            <p>
              You've reached a page that does not exist. Please check the link.
            </p>
            <p>
              If you believe you've found an error, please let us know!
            </p>
          </div>
        </article>
      </div>
    </main>
  )
}

export default NotFound;
