import React from 'react';
import { useLocation, Link} from 'react-router-dom';

const Header = () => {
  const location = useLocation();

  return (
    <header className="header">
      <div className="header-container is-row">
        <div id="logo-container" className="is-col is-mobile is-justified-center">
          <Link to={"/"} className="header-logo">
            <figure className="image">
              <img src={"/images/georgia_places.svg"} alt="Georgia Places"/>
            </figure>
            <p>
              Watch<br />
              Explore<br />
              Live
            </p>
          </Link>
        </div>

        <div className="is-col is-justified-center is-hidden-mobile is-hidden-tablet">
          <nav className="nav is-aligned-center">
            <ul>
              <li>
                <span>Premier ambassadors to Georgia real estate.</span>
              </li>
            </ul>
          </nav>
        </div>

        <div className="is-col is-mobile is-justified-center nav-button-container">
          <nav className="nav is-aligned-right">
            {
              (location && location.pathname.includes('portal')) ? (
                <></>
              ) : (
                <ul>
                  <li>
                    <Link to={"/portal"} className="button is-black has-no-left-margin is-marginless">Portal Login</Link>
                  </li>
                </ul>
              )
            }
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header;
