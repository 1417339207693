import axios, { AxiosInstance, AxiosResponse } from 'axios';

const apiConfig = {
  baseURL: "https://contacthandler.georgia.com",
  headers: { "Content-Type": "text/plain" }
}
const apiInstance: AxiosInstance = axios.create(apiConfig);

apiInstance.interceptors.request.use(
  request => {
    return request;
  },
  (error) => {
    return error;
  }
);

apiInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    throw new Error(error);
  }
);

export const SubmitContactForm = async (formFields: { firstname: string, lastname: string, email: string, phone: string, message: string }) => {
  try {
    let data = JSON.stringify({
      "firstname": formFields.firstname,
      "lastname": formFields.lastname,
      "email": formFields.email,
      "phone": formFields.phone,
      "message": formFields.message
    });
    return await apiInstance.post<AxiosResponse>('/', data);
  } catch(error) {
    throw error;
  }
}
