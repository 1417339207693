import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const PrivacyPolicy = () => {

  useEffect(() => {
    document.title = 'Georgia\u205fPlaces | Privacy Policy';
  }, []);

  return (
    <main className="page-blog-post">
      <div className="page-container">
        <article className="box is-large is-gray is-borderless blog-post-container">
          <div className="featured-image"
               style={{ backgroundImage: "url('/img/privacy_terms_background.jpg')"}} />
          <div className="box is-large mb-0 blog-post-content">
            <h1 className="blog-post-headline">
              Privacy policy
            </h1>
            <p>
              Last updated: May 31, 2022
            </p>
            <div className="box is-gray mb-6">
              <p>
                The privacy of your data is very important to both you and us.
              </p>
              <p>
                This policy applies to all products built and maintained by Georgia Places, Inc.
              </p>
              <p>
                By using Georgia Places websites and Apps you understand that your data in relation with your use of
                these services is processed according to the following Privacy Policy.
              </p>
              <p>
                This Privacy Policy explains what information we collect through your access and use of these services
                and how we use such information; and the security we provide for protecting such information. This
                Privacy Policy should be read and understood as being a complement to
                our <NavLink to={"/legal/terms"}>Terms of Use</NavLink>.
              </p>
            </div>
            <h5>What we collect and why</h5>
            <p>
              <strong>Accounts, identity & access</strong><br />
              When you use Georgia Places services, we ask for certain identifying information such as your name, email
              address and phone number. This is so you can personalize your account, and we can send you updates,
              or other important/relevant information. We may also give you the option to add a profile picture that
              displays in our services. We’ll never sell your personal info to third parties, and we won’t use your name
              in marketing statements without your permission.<br />
              <br />
              <strong>Geolocation data</strong><br />
              We may, at times, log access to user accounts by full IP address in the event we detect malicious activity.
              In these events, this data is used to help identify malicious activity and better help to protect our users
              information and data.<br />
              <br />
              <strong>Website analytics</strong><br />
              When you browse our website, it's pages or applications, your browser automatically shares certain
              information such as which operating system and browser version you are using. We track this information,
              along with the pages you are visiting, page load performance, and in some cases which website referred you
              for analytical purposes like conversion rates and to test new features and designs. We also track specific
              activities such as number of searches, and signups. <br />
              <br />
              <strong>Voluntary correspondence</strong><br />
              When you email Georgia Places or its agents, we keep that correspondence, including the email address, so
              that we have a history of past correspondences to reference if you contact us in the future.<br />
              <br />
              In certain situations we may also store information you provide voluntarily like survey data.
            </p>
          </div>
        </article>
      </div>
    </main>
  )
}

export default PrivacyPolicy;
