import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="box is-large has-gradient-dark">
          <div className="is-row">
            <div className="is-col">
              <div className="box is-transparent">
                <div className="is-row">
                  <div className="is-col">
                    <a className="footer-logo" href="/">
                      <figure className="image">
                        <img src={"/images/georgia_places.svg"} alt="Georgia Places"/>
                      </figure>
                      <p>
                        Watch<br />
                        Explore<br />
                        Live
                      </p>
                    </a>
                    <ul className="is-icon-list">
                      <li>
                        <div className="is-row mt-4">
                          <div className="is-col is-mobile icon-container mr-5">
                            <a href="https://www.facebook.com/georgiaplaces" target="_blank" rel="noreferrer">
                              <figure className="icon">
                                <img src={"/img/icons/icon-facebook-gradient.svg"} alt="Facebook"/>
                              </figure>
                            </a>
                          </div>
                          <div className="is-col is-mobile icon-container mr-5">
                            <a href="https://www.twitter.com/georgiacom" target="_blank" rel="noreferrer">
                              <figure className="icon">
                                <img src={"/img/icons/icon-twitter-gradient.svg"} alt="Twitter"/>
                              </figure>
                            </a>
                          </div>
                          <div className="is-col is-mobile icon-container">
                            <a href="https://www.instagram.com/georgiaplaces/" target="_blank" rel="noreferrer">
                              <figure className="icon">
                                <img src={"/img/icons/icon-instagram-gradient.svg"} alt="YouTube"/>
                              </figure>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="is-row">
            <div className="is-col">
              <div className="box is-transparent">
                <div className="is-row">
                  <div className="is-col is-justified-center">
                    <nav className="nav footer-nav is-aligned-left">
                      <ul>
                        <li>
                          <NavLink
                            to={"/legal/privacy"}
                            className={isActive => "is-borderless mr-0" + (isActive ? " is-active" : "")}
                          >Privacy Policy</NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={"/legal/terms"}
                            className={isActive => "is-borderless" + (isActive ? " is-active" : "")}
                          >Terms of Use</NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="is-col is-justified-center">
                    <p className="copyright">
                      &copy; 2022 Georgia Places&reg;, Inc. All rights reserved.<br />
                      <small>Listing information is deemed reliable but not guaranteed accurate.</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
